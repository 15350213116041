import React from 'react';
import './style.scss';
import { Link } from 'gatsby';

export default class Footer extends React.Component {
    render() {
        return (
            <div className='next-opportunity-footer'>
                <span>© Springworks 2021</span>
                <span className='next-opportunity-term-conditions'>
                    <Link to={'/tnc/'}>Terms & conditions</Link>
                </span>
                <span className='next-opportunity-privacy-policy'>
                    <Link to={'/privacy-policy/'}>Privacy policy</Link>
                </span>
            </div>
        );
    }
}
