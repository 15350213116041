import React from 'react';
import './style.scss';
import Header from '../../components/remote-work-resources/Header';
import Footer from '../../components/remote-work-resources/Footer';
import SuccessScreen from '../../components/remote-work-resources/SuccessScreen';
import { subscribeToMailchimp } from '../../NetworkCalls/RemoteWorkResource';
import { Helmet } from 'react-helmet';

export default class RemoteWorkResource extends React.Component {
    state = {
        name: null,
        email: null,
        emailError: false,
        nameError: false,
        showSuccessScreen: false,
    };

    setField = (fieldName, event) => {
        let args = {};
        args[fieldName] = event.target.value;

        if (this.state[`${fieldName}Error`]) {
            args[`${fieldName}Error`] =
                fieldName === 'email' ? this.isValidEmailAddress(args[fieldName]) : this.isValidName(args[fieldName]);
        }

        this.setState(args);
    };

    onSubmit = async (event) => {
        event.preventDefault();
        const { name, email } = this.state;
        const isValidName = this.isValidName(name);
        const isValidEmail = this.isValidEmailAddress(email);

        if (!isValidEmail || !isValidName) {
            return this.setState({
                nameError: !isValidName,
                emailError: !isValidEmail,
            });
        }
        try {
            await subscribeToMailchimp({ name, email });
            this.setState({ showSuccessScreen: true });
        } catch (error) {
            console.error(error);
        }
    };

    onSignup = () => this.props.history.push('/signup');

    isValidEmailAddress = (value) => {
        if (value) {
            value = value.trim();
            let validEmail = value.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

            return !!validEmail;
        } else {
            return false;
        }
    };

    isValidName = (value) => {
        if (value) return value.trim().length > 2;
        else return false;
    };

    getSuccessScreen = () => {
        return (
            <div className='next-opportunity'>
                <div className='next-opportunity-container'>
                    <Header />
                    <SuccessScreen />
                </div>
                <Footer />
            </div>
        );
    };

    render() {
        const emailErrorClass = this.state.emailError ? 'next-opportunity-download-name-error' : '';
        const nameErrorClass = this.state.nameError ? 'next-opportunity-download-name-error' : '';

        if (this.state.showSuccessScreen) return this.getSuccessScreen();

        return (
            <div className='next-opportunity'>
                <Helmet>
                    <title>Remote Work Resources | Springworks</title>
                    <meta
                        name='description'
                        content='We have prepared a curated resource repository of articles, books, podcasts, tools, products and more you need to start, run, manage or be part of a productive remote team.'
                    />
                    <meta property='og:site_name' content='Springworks' />
                    <meta property='og:title' content='Remote Work Resources | Springworks' />
                    <meta
                        property='og:description'
                        content='We have prepared a curated resource repository of articles, books, podcasts, tools, products and more you need to start, run, manage or be part of a productive remote team.'
                    />
                    <meta property='og:url' content='https://www.springworks.in/remote-work-resources/' />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:image'
                        content='https://sw-assets.gumlet.io/remote-work-resources/Remote+Work+Resources.jpg'
                    />
                    <meta
                        property='og:image:secure_url'
                        content='https://sw-assets.gumlet.io/remote-work-resources/Remote+Work+Resources.jpg'
                    />
                    <meta property='og:image:width' content='1280' />
                    <meta property='og:image:height' content='640' />
                    <meta property='twitter:title' content='Remote Work Resources | Springworks' />
                    <meta
                        property='twitter:description'
                        content='We have prepared a curated resource repository of articles, books, podcasts, tools, products and more you need to start, run, manage or be part of a productive remote team.'
                    />
                    <meta property='twitter:card' content='website' />
                    <meta property='twitter:card' content='summary_large_image' />
                    <meta
                        property='twitter:image'
                        content='https://sw-assets.gumlet.io/remote-work-resources/Remote+Work+Resources.jpg'
                    />
                    <meta property='twitter:site' content='@springroleinc' />
                    <link rel='canonical' href='https://www.springworks.in/remote-work-resources/' />
                </Helmet>
                <div className='next-opportunity-container'>
                    <Header />

                    <div className='next-opportunity-download'>
                        <div className='next-opportunity-download-pattern'>
                            <img
                                src={'https://sw-assets.gumlet.io/remote-work-resources/download-pattern.png'}
                                data-src={'https://sw-assets.gumlet.io/remote-work-resources/download-pattern.png'}
                                alt={'download'}
                            />
                        </div>
                        <div className='next-opportunity-download-content'>
                            <h1>
                                <span>
                                    A curated resource repository of articles, books, podcasts, tools, products and more
                                    you need to start, run or be part of a productive remote team.{' '}
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>

                <div className='next-opportunity-form-section'>
                    <div className='next-opportunity-container'>
                        <div className='next-opportunity-form-section-iframe'>
                            <iframe
                                src='https://airtable.com/embed/shrTbU9feWSOcKDXA/tblsFYimCq7wk34Ty?backgroundColor=cyan&viewControls=on&blocks=hide'
                                title='airtableEmployeesIFrame'
                            />

                            <p>
                                Click on the little arrow next to "Content type" to add a filter to segment types of
                                content.
                            </p>
                        </div>

                        <div className='next-opportunity-form-download-card'>
                            <div className='next-opportunity-txt-content'>
                                You can download all of the content (300+ items) in a single .csv file by sharing your
                                name and e-mail below.
                            </div>
                            <div className='next-opportunity-name-email'>
                                <div className={`next-opportunity-download-name ${nameErrorClass}`}>
                                    <input placeholder='Enter Your Name' onChange={this.setField.bind(null, 'name')} />
                                    {this.state.nameError ? (
                                        <img
                                            src={'https://sw-assets.gumlet.io/remote-work-resources/error-icon.svg'}
                                            data-src={
                                                'https://sw-assets.gumlet.io/remote-work-resources/error-icon.svg'
                                            }
                                            alt={'error'}
                                        />
                                    ) : (
                                        <img
                                            src={'https://sw-assets.gumlet.io/remote-work-resources/name-icon.svg'}
                                            data-src={'https://sw-assets.gumlet.io/remote-work-resources/name-icon.svg'}
                                            alt={'name'}
                                        />
                                    )}
                                </div>
                                <div className={`next-opportunity-download-name ${emailErrorClass}`}>
                                    <input
                                        placeholder='Enter Your Email'
                                        onChange={this.setField.bind(null, 'email')}
                                    />
                                    {this.state.emailError ? (
                                        <img
                                            src={'https://sw-assets.gumlet.io/remote-work-resources/error-icon.svg'}
                                            data-src={
                                                'https://sw-assets.gumlet.io/remote-work-resources/error-icon.svg'
                                            }
                                            alt={'error'}
                                        />
                                    ) : (
                                        <img
                                            src={'https://sw-assets.gumlet.io/remote-work-resources/email-icon.svg'}
                                            data-src={
                                                'https://sw-assets.gumlet.io/remote-work-resources/email-icon.svg'
                                            }
                                            alt={'name'}
                                        />
                                    )}
                                </div>
                                <div className='next-opportunity-download-button'>
                                    <button onClick={this.onSubmit}>Get Download Link</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='next-opportunity-standup-idea'>
                    <div className='next-opportunity-container'>
                        <div className='next-opportunity-meeting-icon'>
                            <img
                                src={'https://sw-assets.gumlet.io/remote-work-resources/icon.png'}
                                data-src={'https://sw-assets.gumlet.io/remote-work-resources/icon.png'}
                                alt={''}
                            />
                        </div>
                        <h3>Want to suggest content for the list?</h3>
                        <p>
                            We'd love to hear about valuable content related to Remote Work that you've read, written,
                            watched or listened to! We’re especially looking for more Audio (great Podcast episodes) and
                            Video (explainers or interviews) content. Please fill out the full form below to suggest
                            your content. Your entry will be reviewed and considered to be added in due time.
                        </p>
                        <div className='next-oppertunity-Fillup_form-Icon'>
                            <a href='https://forms.gle/LLZrj8u5MxiWdv7F7/' target='_blank' rel='noreferrer noopener'>
                                <p>
                                    Fill up a short form to get added to the public list<span>→</span>
                                </p>
                            </a>
                        </div>
                    </div>
                </div>

                <div className='next-opportunity-who-made-this'>
                    <div className='next-opportunity-who-made-this-header'>
                        <h2>Who made this?</h2>
                    </div>
                    <div className='next-opportunity-who-made-this-content'>
                        <div className='next-opportunity-who-made-this-springrecruit-logo'>
                            <img
                                src={'https://sw-assets.gumlet.io/springworks/springworks.svg'}
                                data-src={'https://sw-assets.gumlet.io/springworks/springworks.svg'}
                                alt={'springworks'}
                            />
                        </div>
                        <div className='next-opportunity-who-made-this-springrecruit-para'>
                            <p>
                                <span>Hi! We’re Springworks.</span> We are building tools and products to usher in the
                                Future of Recruiting! Here’re some of our products and tools:
                            </p>
                            <p>
                                SpringRole is our LinkedIn-like, but decentralized and verified professional profile
                                platform built on Blockchain.{' '}
                                <a target='_blank' rel='noreferrer noopener' href='https://springrole.com/'>
                                    {' '}
                                    You can create your profile here.
                                </a>
                            </p>
                            <p>
                                SpringVerify is our employee background verification built for 2020 and beyond, backed
                                by Blockchain.{' '}
                                <a target='_blank' rel='noreferrer noopener' href='https://springverify.com/'>
                                    Check it out here.
                                </a>
                            </p>
                            <p>
                                SpringRecruit, currently in beta, is our forever-free Applicant Tracking System (ATS)
                                built for smaller teams to save them the hassles of Google Sheets and Excel files.{' '}
                                <a target='_blank' rel='noreferrer noopener' href='https://www.springrecruit.com/'>
                                    Sign up
                                </a>{' '}
                                to get access!
                            </p>
                            <div className='next-opportunity-download-pattern-bottom'>
                                <img
                                    src={'https://sw-assets.gumlet.io/remote-work-resources/download-pattern.png'}
                                    data-src={'https://sw-assets.gumlet.io/remote-work-resources/download-pattern.png'}
                                    alt={''}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}
