import axios from 'axios';

const baseURL = 'https://fhbm0stpma.execute-api.us-east-1.amazonaws.com/prod';

const apiCaller = async ({ path, method, body }) => {
    const url = `${baseURL}${path}`;
    const args = {
        method,
        url,
        data: body,
        responseType: 'json',
    };

    return axios(args);
};

export const subscribeToMailchimp = async ({ name, email }) => {
    try {
        const payload = {
            body: {
                name,
                email,
            },
            path: '/subscribe',
            method: 'POST',
        };
        await apiCaller(payload);
        return true;
    } catch (error) {
        throw error;
    }
};
